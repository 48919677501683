'use client';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import { WhenTakenLogo } from '@wt/game/assets/logo/WhenTakenLogo';
import { WhereTakenLogo } from '@wt/game/assets/logo/WhereTakenLogo';
import { useBannerAdOpen } from '@wt/game/providers/bannerAdOpen/BannerAdOpenProvider';
import { getSiteMode } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { ReactNode, useMemo } from 'react';

import { AccountAvatar } from './AccountAvatar';
import { UploadButton } from './UploadButton';
import { WhenTakenMoviesLogo } from '@wt/game/assets/logo/WhenTakenMoviesLogo';

const PageHeader = (props: {
  children?: ReactNode;
  openSettingsModal: () => void;
}) => {
  const { adOpen } = useBannerAdOpen();

  const top = useMemo(() => {
    return adOpen ? 'top-[50px] lg:top-[90px]' : 'top-1';
  }, [adOpen]);

  return (
    <div
      className={`sticky ${top} z-40 mb-2 flex min-h-[53px] items-center justify-center border-b border-solid border-secondary bg-base-100 px-4 py-2 md:mb-0 md:min-h-[62px] md:px-12 lg:min-h-[75px]`}
    >
      <header className="flex w-full flex-row items-center  justify-between text-2xl md:text-4xl">
        <Link href="/" className="max-w-[280px] flex-1">
          {getSiteMode() == 'whentaken' && <WhenTakenLogo />}
          {getSiteMode() == 'whentaken-movies' && <WhenTakenMoviesLogo />}
          {getSiteMode() == 'wheretaken' && <WhereTakenLogo />}
        </Link>
        {props.children}
        <HeaderIconButtons openSettingsModal={props.openSettingsModal} />
      </header>
    </div>
  );
};

export default PageHeader;

const HeaderIconButtons = (props: { openSettingsModal: () => void }) => {
  return (
    <div className="flex flex-row gap-3">
      <Link href={'/teuteuf-games'}>
        <SportsEsportsOutlinedIcon
          fontSize="large"
          className="hidden md:flex"
        />
        <SportsEsportsOutlinedIcon
          fontSize="medium"
          className="flex md:hidden"
        />
      </Link>
      <UploadButton />
      <Link href={'/stats'}>
        <InsertChartOutlinedIcon fontSize="large" className="hidden md:flex" />
        <InsertChartOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
      <Link href={'#'} onClick={props.openSettingsModal}>
        <SettingsOutlinedIcon fontSize="large" className="hidden md:flex" />
        <SettingsOutlinedIcon fontSize="medium" className="flex md:hidden" />
      </Link>
      <AccountAvatar />
    </div>
  );
};
