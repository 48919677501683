import { Logo } from './logo';

export const WhenTakenMoviesLogo = () => {
  return (
    <div className="relative top-1 inline-flex flex-row flex-1 w-full items-center gap-1.5">
      <img src="/assets/images/clapperboard.png" className="size-10" />
      <div className="hidden md:block flex-1 w-0 max-w-[232px]">
        <Logo />
      </div>
    </div>
  );
};
