export const Logo = () => {
  return (
    <svg viewBox="0 0 232 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M224.609 24.2144L221.286 24.419C221.229 24.1349 221.107 23.8792 220.919 23.6519C220.732 23.419 220.484 23.2343 220.178 23.098C219.876 22.9559 219.516 22.8849 219.095 22.8849C218.533 22.8849 218.058 23.0042 217.672 23.2429C217.286 23.4758 217.092 23.7883 217.092 24.1804C217.092 24.4929 217.217 24.7571 217.467 24.973C217.717 25.1889 218.146 25.3622 218.754 25.4929L221.124 25.9701C222.396 26.2315 223.345 26.6519 223.97 27.2315C224.595 27.811 224.908 28.5724 224.908 29.5156C224.908 30.3735 224.655 31.1264 224.149 31.7741C223.649 32.4218 222.962 32.9275 222.087 33.2911C221.217 33.6491 220.215 33.8281 219.078 33.8281C217.345 33.8281 215.965 33.4673 214.936 32.7457C213.913 32.0184 213.314 31.0298 213.138 29.7798L216.709 29.5923C216.817 30.1207 217.078 30.5241 217.493 30.8025C217.908 31.0752 218.439 31.2116 219.087 31.2116C219.723 31.2116 220.234 31.0894 220.621 30.8451C221.013 30.5951 221.212 30.2741 221.217 29.8821C221.212 29.5525 221.072 29.2826 220.8 29.0724C220.527 28.8565 220.107 28.6917 219.538 28.5781L217.271 28.1264C215.993 27.8707 215.041 27.4275 214.416 26.7968C213.797 26.1661 213.487 25.3622 213.487 24.3849C213.487 23.544 213.715 22.8196 214.169 22.2116C214.629 21.6036 215.274 21.1349 216.104 20.8054C216.939 20.4758 217.916 20.311 219.036 20.311C220.689 20.311 221.99 20.6605 222.939 21.3593C223.893 22.0582 224.45 23.0099 224.609 24.2144Z"
        fill="white"
      />
      <path
        d="M205.34 33.8281C203.993 33.8281 202.834 33.5554 201.862 33.0099C200.896 32.4588 200.152 31.6804 199.629 30.6747C199.107 29.6633 198.845 28.4673 198.845 27.0866C198.845 25.74 199.107 24.5582 199.629 23.5411C200.152 22.5241 200.888 21.7315 201.837 21.1633C202.791 20.5951 203.911 20.311 205.195 20.311C206.058 20.311 206.862 20.4502 207.607 20.7286C208.357 21.0014 209.01 21.4133 209.567 21.9644C210.129 22.5156 210.567 23.2088 210.879 24.044C211.192 24.8735 211.348 25.8451 211.348 26.9588V27.9559H200.294V25.7059H207.93C207.93 25.1832 207.817 24.7201 207.59 24.3167C207.362 23.9133 207.047 23.598 206.644 23.3707C206.246 23.1377 205.783 23.0213 205.254 23.0213C204.703 23.0213 204.215 23.1491 203.788 23.4048C203.368 23.6548 203.038 23.9929 202.8 24.419C202.561 24.8394 202.439 25.3082 202.433 25.8252V27.9644C202.433 28.6122 202.553 29.1718 202.791 29.6434C203.036 30.115 203.379 30.4786 203.822 30.7343C204.266 30.99 204.791 31.1179 205.399 31.1179C205.803 31.1179 206.172 31.061 206.507 30.9474C206.842 30.8338 207.129 30.6633 207.368 30.436C207.607 30.2088 207.788 29.9304 207.913 29.6008L211.271 29.8224C211.101 30.6292 210.751 31.3338 210.223 31.936C209.7 32.5326 209.024 32.9985 208.195 33.3338C207.371 33.6633 206.419 33.8281 205.34 33.8281Z"
        fill="white"
      />
      <path
        d="M192.835 33.5723V20.4814H196.465V33.5723H192.835ZM194.658 18.7939C194.119 18.7939 193.656 18.6149 193.269 18.257C192.889 17.8933 192.698 17.4587 192.698 16.953C192.698 16.453 192.889 16.024 193.269 15.6661C193.656 15.3024 194.119 15.1206 194.658 15.1206C195.198 15.1206 195.658 15.3024 196.039 15.6661C196.425 16.024 196.619 16.453 196.619 16.953C196.619 17.4587 196.425 17.8933 196.039 18.257C195.658 18.6149 195.198 18.7939 194.658 18.7939Z"
        fill="white"
      />
      <path
        d="M190.977 20.4814L186.4 33.5724H182.309L177.732 20.4814H181.567L184.286 29.8479H184.423L187.133 20.4814H190.977Z"
        fill="white"
      />
      <path
        d="M170.39 33.8281C169.066 33.8281 167.921 33.5468 166.955 32.9843C165.995 32.4161 165.254 31.6264 164.731 30.615C164.208 29.598 163.947 28.419 163.947 27.0781C163.947 25.7258 164.208 24.544 164.731 23.5326C165.254 22.5156 165.995 21.7258 166.955 21.1633C167.921 20.5951 169.066 20.311 170.39 20.311C171.714 20.311 172.856 20.5951 173.816 21.1633C174.782 21.7258 175.526 22.5156 176.049 23.5326C176.572 24.544 176.833 25.7258 176.833 27.0781C176.833 28.419 176.572 29.598 176.049 30.615C175.526 31.6264 174.782 32.4161 173.816 32.9843C172.856 33.5468 171.714 33.8281 170.39 33.8281ZM170.407 31.0156C171.009 31.0156 171.512 30.8451 171.916 30.5042C172.319 30.1576 172.623 29.686 172.827 29.0894C173.038 28.4929 173.143 27.8139 173.143 27.0525C173.143 26.2911 173.038 25.6122 172.827 25.0156C172.623 24.419 172.319 23.9474 171.916 23.6008C171.512 23.2542 171.009 23.0809 170.407 23.0809C169.799 23.0809 169.288 23.2542 168.873 23.6008C168.464 23.9474 168.154 24.419 167.944 25.0156C167.739 25.6122 167.637 26.2911 167.637 27.0525C167.637 27.8139 167.739 28.4929 167.944 29.0894C168.154 29.686 168.464 30.1576 168.873 30.5042C169.288 30.8451 169.799 31.0156 170.407 31.0156Z"
        fill="white"
      />
      <path
        d="M142.582 16.1177H147.134L151.94 27.8449H152.145L156.952 16.1177H161.503V33.5722H157.923V22.2114H157.778L153.261 33.487H150.824L146.307 22.1688H146.162V33.5722H142.582V16.1177Z"
        fill="white"
      />
      <path
        d="M124.129 25.6974V33.5724H122.117V20.4815H124.06V22.5269H124.231C124.538 21.8622 125.004 21.3281 125.629 20.9247C126.254 20.5156 127.06 20.311 128.049 20.311C128.935 20.311 129.711 20.4929 130.376 20.8565C131.04 21.2144 131.558 21.7599 131.927 22.4929C132.296 23.2201 132.481 24.1406 132.481 25.2542V33.5724H130.469V25.3906C130.469 24.3622 130.202 23.561 129.668 22.9872C129.134 22.4076 128.401 22.1179 127.469 22.1179C126.827 22.1179 126.254 22.2571 125.748 22.5355C125.248 22.8139 124.853 23.2201 124.563 23.7542C124.273 24.2883 124.129 24.936 124.129 25.6974Z"
        fill="white"
      />
      <path
        d="M113.637 33.8451C112.376 33.8451 111.288 33.5667 110.373 33.0099C109.464 32.4474 108.762 31.6633 108.268 30.6576C107.779 29.6463 107.535 28.4701 107.535 27.1292C107.535 25.7883 107.779 24.6065 108.268 23.5838C108.762 22.5554 109.45 21.7542 110.33 21.1804C111.217 20.6008 112.251 20.311 113.433 20.311C114.114 20.311 114.788 20.4247 115.452 20.6519C116.117 20.8792 116.722 21.2485 117.268 21.7599C117.813 22.2656 118.248 22.936 118.572 23.7713C118.896 24.6065 119.058 25.6349 119.058 26.8565V27.7088H108.967V25.9701H117.012C117.012 25.2315 116.864 24.5724 116.569 23.9929C116.279 23.4133 115.864 22.9559 115.325 22.6207C114.79 22.2855 114.16 22.1179 113.433 22.1179C112.631 22.1179 111.938 22.3167 111.353 22.7144C110.773 23.1065 110.327 23.6179 110.015 24.2485C109.702 24.8792 109.546 25.5554 109.546 26.2769V27.436C109.546 28.4247 109.717 29.2627 110.058 29.9502C110.404 30.6321 110.884 31.1519 111.498 31.5099C112.111 31.8622 112.825 32.0383 113.637 32.0383C114.165 32.0383 114.643 31.9644 115.069 31.8167C115.501 31.6633 115.873 31.436 116.185 31.1349C116.498 30.8281 116.739 30.4474 116.91 29.9929L118.853 30.5383C118.648 31.1974 118.305 31.7769 117.822 32.2769C117.339 32.7713 116.742 33.1576 116.032 33.436C115.322 33.7088 114.523 33.8451 113.637 33.8451Z"
        fill="white"
      />
      <path
        d="M97.5078 28.7995L97.4737 26.3109H97.8828L103.61 20.4813H106.099L99.9964 26.6518H99.826L97.5078 28.7995ZM95.6328 33.5722V16.1177H97.6442V33.5722H95.6328ZM103.951 33.5722L98.8374 27.0949L100.269 25.6972L106.508 33.5722H103.951Z"
        fill="white"
      />
      <path
        d="M85.9616 33.8792C85.1321 33.8792 84.3793 33.723 83.7031 33.4105C83.027 33.0923 82.49 32.6349 82.0923 32.0383C81.6946 31.436 81.4957 30.7088 81.4957 29.8565C81.4957 29.1065 81.6435 28.4985 81.9389 28.0326C82.2344 27.561 82.6292 27.1917 83.1236 26.9247C83.6179 26.6576 84.1633 26.4588 84.7599 26.3281C85.3622 26.1917 85.9673 26.0838 86.5753 26.0042C87.3707 25.9019 88.0156 25.8252 88.5099 25.7741C89.0099 25.7173 89.3736 25.6235 89.6008 25.4929C89.8338 25.3622 89.9503 25.1349 89.9503 24.811V24.7429C89.9503 23.9019 89.7202 23.2485 89.2599 22.7826C88.8054 22.3167 88.115 22.0838 87.1889 22.0838C86.2287 22.0838 85.4758 22.294 84.9304 22.7144C84.3849 23.1349 84.0014 23.5838 83.7798 24.061L81.8707 23.3792C82.2116 22.5838 82.6662 21.9644 83.2344 21.5213C83.8082 21.0724 84.4332 20.7599 85.1094 20.5838C85.7912 20.4019 86.4616 20.311 87.1207 20.311C87.5412 20.311 88.0241 20.3622 88.5696 20.4644C89.1207 20.561 89.652 20.7627 90.1633 21.0696C90.6804 21.3764 91.1094 21.8394 91.4503 22.4588C91.7912 23.0781 91.9616 23.9076 91.9616 24.9474V33.5724H89.9503V31.7997H89.848C89.7116 32.0838 89.4844 32.3877 89.1662 32.7116C88.848 33.0355 88.4247 33.311 87.8963 33.5383C87.3679 33.7656 86.723 33.8792 85.9616 33.8792ZM86.2685 32.0724C87.0639 32.0724 87.7344 31.9161 88.2798 31.6036C88.831 31.2911 89.2457 30.8877 89.5241 30.3934C89.8082 29.8991 89.9503 29.3792 89.9503 28.8338V26.9929C89.865 27.0951 89.6775 27.1889 89.3878 27.2741C89.1037 27.3536 88.7741 27.4247 88.3991 27.4872C88.0298 27.544 87.669 27.5951 87.3167 27.6406C86.9702 27.6804 86.6889 27.7144 86.473 27.7429C85.9503 27.811 85.4616 27.9218 85.0071 28.0752C84.5582 28.223 84.1946 28.4474 83.9162 28.7485C83.6435 29.044 83.5071 29.4474 83.5071 29.9588C83.5071 30.6576 83.7656 31.186 84.2827 31.544C84.8054 31.8963 85.4673 32.0724 86.2685 32.0724Z"
        fill="white"
      />
      <path
        d="M79.0838 20.4816V22.1861H72.2997V20.4816H79.0838ZM74.277 17.3452H76.2884V29.8225C76.2884 30.3907 76.3708 30.8168 76.5355 31.1009C76.706 31.3793 76.9219 31.5668 77.1833 31.6634C77.4503 31.7543 77.7316 31.7998 78.027 31.7998C78.2486 31.7998 78.4304 31.7884 78.5725 31.7657C78.7145 31.7373 78.8282 31.7145 78.9134 31.6975L79.3225 33.5043C79.1861 33.5554 78.9958 33.6066 78.7514 33.6577C78.5071 33.7145 78.1975 33.7429 77.8225 33.7429C77.2543 33.7429 76.6975 33.6208 76.152 33.3765C75.6122 33.1321 75.1634 32.76 74.8054 32.26C74.4532 31.76 74.277 31.1293 74.277 30.3679V17.3452Z"
        fill="white"
      />
      <path
        d="M61.3629 25.6974V33.5724H59.3516V20.4815H61.2947V22.5269H61.4652C61.772 21.8622 62.2379 21.3281 62.8629 20.9247C63.4879 20.5156 64.2947 20.311 65.2834 20.311C66.1697 20.311 66.9453 20.4929 67.6101 20.8565C68.2749 21.2144 68.7919 21.7599 69.1612 22.4929C69.5305 23.2201 69.7152 24.1406 69.7152 25.2542V33.5724H67.7038V25.3906C67.7038 24.3622 67.4368 23.561 66.9027 22.9872C66.3686 22.4076 65.6357 22.1179 64.7038 22.1179C64.0618 22.1179 63.4879 22.2571 62.9822 22.5355C62.4822 22.8139 62.0874 23.2201 61.7976 23.7542C61.5078 24.2883 61.3629 24.936 61.3629 25.6974Z"
        fill="white"
      />
      <path
        d="M50.8714 33.8451C49.6101 33.8451 48.522 33.5667 47.6072 33.0099C46.6981 32.4474 45.9964 31.6633 45.5021 30.6576C45.0135 29.6463 44.7692 28.4701 44.7692 27.1292C44.7692 25.7883 45.0135 24.6065 45.5021 23.5838C45.9964 22.5554 46.6839 21.7542 47.5646 21.1804C48.451 20.6008 49.4851 20.311 50.6669 20.311C51.3487 20.311 52.022 20.4247 52.6868 20.6519C53.3516 20.8792 53.9567 21.2485 54.5021 21.7599C55.0476 22.2656 55.4822 22.936 55.8061 23.7713C56.13 24.6065 56.2919 25.6349 56.2919 26.8565V27.7088H46.201V25.9701H54.2464C54.2464 25.2315 54.0987 24.5724 53.8033 23.9929C53.5135 23.4133 53.0987 22.9559 52.5589 22.6207C52.0248 22.2855 51.3942 22.1179 50.6669 22.1179C49.8658 22.1179 49.1726 22.3167 48.5873 22.7144C48.0078 23.1065 47.5618 23.6179 47.2493 24.2485C46.9368 24.8792 46.7805 25.5554 46.7805 26.2769V27.436C46.7805 28.4247 46.951 29.2627 47.2919 29.9502C47.6385 30.6321 48.1186 31.1519 48.7322 31.5099C49.3459 31.8622 50.0589 32.0383 50.8714 32.0383C51.3998 32.0383 51.8771 31.9644 52.3033 31.8167C52.7351 31.6633 53.1072 31.436 53.4197 31.1349C53.7322 30.8281 53.9737 30.4474 54.1442 29.9929L56.0873 30.5383C55.8828 31.1974 55.5391 31.7769 55.0561 32.2769C54.5731 32.7713 53.9766 33.1576 53.2663 33.436C52.5561 33.7088 51.7578 33.8451 50.8714 33.8451Z"
        fill="white"
      />
      <path
        d="M33.2145 25.6972V33.5722H31.2031V16.1177H33.2145V22.5268H33.3849C33.6918 21.8506 34.152 21.3137 34.7656 20.916C35.3849 20.5126 36.2088 20.3109 37.2372 20.3109C38.1293 20.3109 38.9105 20.4898 39.581 20.8478C40.2514 21.2001 40.7713 21.7427 41.1406 22.4756C41.5156 23.2029 41.7031 24.129 41.7031 25.254V33.5722H39.6918V25.3904C39.6918 24.3506 39.4219 23.5467 38.8821 22.9785C38.348 22.4046 37.6065 22.1177 36.6577 22.1177C35.9986 22.1177 35.4077 22.2569 34.8849 22.5353C34.3679 22.8137 33.9588 23.2199 33.6577 23.754C33.3622 24.2881 33.2145 24.9359 33.2145 25.6972Z"
        fill="white"
      />
      <path
        d="M11.8651 33.5722L7.09235 16.1177H9.24007L12.8878 30.3336H13.0583L16.7742 16.1177H19.1605L22.8764 30.3336H23.0469L26.6946 16.1177H28.8423L24.0696 33.5722H21.8878L18.0355 19.6631H17.8992L14.0469 33.5722H11.8651Z"
        fill="white"
      />
    </svg>
  );
};
